import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import addToMailchimp from "gatsby-plugin-mailchimp"

import ogImage from "../images/referral.png"

const Referrals = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [referree, setReferree] = useState("")
  const [referreeEmail, setReferreeEmail] = useState("")
  const [org, setOrg] = useState("")
  const [reason, setReason] = useState("")

  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(
      email,
      {
        NAME: name,
        REFERREE: referree,
        REF_EMAIL: referreeEmail,
        ORG: org,
        REASON: reason,
      },
      "https://quantcopy.us17.list-manage.com/subscribe/post?u=7323b3f565a395e36c758893b&amp;id=b7aa561421"
    )
      .then(data => {
        if (data.result === "error") {
          alert(data.msg)
        }
        if (data.result === "success") {
          setIsSuccess(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Layout>
      <SEO
        title="Tactic Customer Referral Program"
        description="We've grown through word-of-mouth, and we'd like to keep it that way. We will give you a £150 Amazon voucher for every successful referral."
        image={ogImage}
      />

      <section className="container text-white mx-auto p-4 sm:p-6">
        <div className="max-w-3xl flex flex-col gap-4 mt-24">
          <h2 className="uppercase text-medium-grey">
            Tactic Customer Referral Programe
          </h2>
          <h1 className="text-6xl font-bold">
            £150 for every successful customer referral
          </h1>
        </div>

        <div className="flex flex-col md:flex-row gap-24 my-12">
          <div className="flex-1 prose prose-xl">
            <p>
              We have been lucky to get great word of mouth from our users, and
              we would like to continue learning from your friends and enabling
              your network to be successful.
            </p>
            <p>
              Get a £150 Amazon voucher for every successful referral. That's a
              company with more than 100 employees, and the head of sales,
              marketing or operations (or more senior) taking a demo call with
              us.
            </p>
            <h3>How it works</h3>
            <ol>
              <li>Fill out the form.</li>
              <li>We will reach out to them.</li>
              <li>
                If they attend a demo call, we will send a £150 Amazon voucher
                your way!
              </li>
            </ol>
            <p>Thank you again for being a big supporter of Tactic!</p>
            <small className="text-dark-grey">
              Disclaimer: Tactic reserves the right and discretion to accept a
              referral. The details of the offering can change without prior
              notice.
            </small>
          </div>

          <div className="flex-1 rounded-md p-12 bg-[#333] border border-lilac">
            {isSuccess && (
              <div className="p-3 rounded-lg bg-green-800 mb-12 text-lg">
                Success! Thank you again for being a big supporter of Tactic.
              </div>
            )}
            {!isSuccess && (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <label>What's your name?*</label>
                <input
                  className="shadow-button bg-light-grey rounded-lg text-dark-grey pl-3 w-full h-10 leading-none focus:outline-none focus:ring focus:ring-lilac"
                  placeholder="Michael Scott"
                  onChange={e => setReferree(e.currentTarget.value)}
                ></input>

                <label>What's your email?*</label>
                <input
                  className="shadow-button bg-light-grey rounded-lg text-dark-grey pl-3 w-full h-10 leading-none focus:outline-none focus:ring focus:ring-lilac"
                  placeholder="mike@dundermifflin.com"
                  onChange={e => setReferreeEmail(e.currentTarget.value)}
                ></input>

                <label>Who are you referring?</label>
                <input
                  className="shadow-button bg-light-grey rounded-lg text-dark-grey pl-3 w-full h-10 leading-none focus:outline-none focus:ring focus:ring-lilac"
                  placeholder="Leslie Knope"
                  onChange={e => setName(e.currentTarget.value)}
                ></input>

                <label>What's their email?*</label>
                <input
                  className="shadow-button bg-light-grey rounded-lg text-dark-grey pl-3 w-full h-10 leading-none focus:outline-none focus:ring focus:ring-lilac"
                  placeholder="leslie@pawnee.gov"
                  onChange={e => setEmail(e.currentTarget.value)}
                ></input>

                <label>Where do they work?</label>
                <input
                  className="shadow-button bg-light-grey rounded-lg text-dark-grey pl-3 w-full h-10 leading-none focus:outline-none focus:ring focus:ring-lilac"
                  placeholder="P&R"
                  onChange={e => setOrg(e.currentTarget.value)}
                ></input>

                <label>
                  What would be most interesting to them about Tactic?*
                </label>

                <textarea
                  className="shadow-button bg-light-grey rounded-lg text-dark-grey pl-3 w-full focus:outline-none focus:ring focus:ring-lilac"
                  name="Reason"
                  rows="5"
                  onChange={e => setReason(e.currentTarget.value)}
                ></textarea>

                <button
                  type="submit"
                  className="bg-lilac hover:bg-lilac-dark py-3 px-4 shadow-button text-light-grey text-lg font-semibold rounded-lg leading-none cursor-pointer transition whitespace-nowrap"
                >
                  Refer
                </button>
              </form>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Referrals
